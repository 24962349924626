import React from 'react';

const BioinformaticsCopilot = () => {
  return (
    <div>
      <h1>Bioinformatics Copilot</h1>
      <p>Welcome to the Bioinformatics Copilot page. Here, you can download the software for your operating system:</p>
      <ul>
        <li>
          <a href="/downloads/bioinformaticscopilot_macOS.zip" download>
            Download for macOS
          </a>
        </li>
        <li>
          <a href="/downloads/bioinformaticscopilot_Windows.zip" download>
            Download for Windows
          </a>
        </li>
      </ul>
    </div>
  );
};

export default BioinformaticsCopilot;

